import Form from "../components/form";
import React from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import get from "lodash/get";
import SEO from "../components/seo";

const ContactUs = ({data, location}) => {
  const bannerLink = get(data, 'contentfulAsset.file.url')
  const allKeywords = get(data, 'allContentfulPageKeywords.nodes')

  return (
    <Layout location={location}>
      <SEO title={'HomeBest have CE, CB, GCC, SQM(Saudi Quality Mark) and SASO certificates'} keywords={allKeywords[0]?.keywords}/>
      <img src={bannerLink} alt=""/>
      <div className="container d-flex justify-content-center p-4">
        <div className="col-3 p-4">
          <div className="text-center">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                   className="bi bi-building" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                <path
                  d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
              </svg>
            </div>
            <div className="h2 p-3">
              Email/Website
            </div>
            <div>
              <p>
                ecb@chinalandlink.com
              </p>
              <p>
                https://www.hbsocket.com
              </p>
            </div>
          </div>
        </div>
        <div className="col-3 p-4">
          <div className="text-center">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                   className="bi bi-geo-alt"
                   viewBox="0 0 16 16">
                <path
                  d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
              </svg>
            </div>
            <div>
              <div className="h2 p-3">
                Add
              </div>
              <div>
                <p>
                  Room 806, No.1 Building, Smart and Technology Parks, No.18 Keji Road，Cixi City, Ningbo, China
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 p-4">
          <div className="text-center">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                   className="bi bi-telephone-inbound" viewBox="0 0 16 16">
                <path
                  d="M15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0zm-12.2 1.182a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
              </svg>
            </div>
            <div className="h2 p-3">
              Tel
            </div>
            <div>
              <p>
                +86-574-63811277
              </p>
              <p>
                +86 13396747158
              </p>
            </div>
          </div>
        </div>

      </div>
      <main>
        <div>
          <Form lang={'en'}/>
        </div>
      </main>
    </Layout>
  )
}

export default ContactUs;

export const pageQuery = graphql`
  query ContactUsQuery {
    allContentfulPageKeywords(filter: {page: {eq: "contactus"}, node_locale: {eq: "en-US"}}) {
        nodes {
            keywords
            node_locale
            page
        }
    }
    contentfulAsset(title: {eq: "contact_us_banner"}) {
      file {
        url
      }
    }
  }
`


